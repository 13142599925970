
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchBar from "@/components/search-bars/SearchBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';


export default defineComponent({
  name: "BCN Report",
  mixins: [apiEndpoint],
  components: {
    Form,
    Datatable,
    SearchBar,
    Swal,
  },
  data() {
    return {
      api_url: "",
      load: false,
      loading: false,
      tranche: [] as any,
      association: [] as any,
      btnCheck: false,

      training_date: "" as any,

      showTrainningStatistics: false,
      componentKey: 0,
      actionActive: false,
      activeStatusHistoryInfo: [] as any,

      tmsdashboardInfo: [] as any,

      FinalTotalTrainee: 0 as any,
      FinalTotalEnrollment: 0 as any,
      FinalTotalfemaleEnrollment: 0 as any,

      FinalTotalCertification: 0 as any,

      FinalTotalJobPlacement: 0 as any,
      FinalTotalPercentageOfJobPlacement: 0 as any,
    };
  },
  async created() { },
  methods: {
    async printPdf() {
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('entity', entity_id);
      formData.set('institute', institute_id);
      formData.set('training_date', this.training_date);

      axios
        .post(`${this.VUE_APP_API_URL}/api/report/activity-report_print`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('entity', entity_id);
      formData.set('institute', institute_id);
      formData.set('training_date', this.training_date);
      await ApiService.post('report/active-status-report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('entity', entity_id);
      formData.set('institute', institute_id);
      formData.set('training_date', this.training_date);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/active-status-report_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'active-status-report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async activeStatusHistory() {
      this.load = true;
      this.showTrainningStatistics = true;
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        "report/active-status-history?training_date=" + this.training_date + '&entity=' +
        entity_id +
        '&institute=' +
        institute_id
      )
        .then((response) => {
          //this.activeStatusHistoryInfo = response.data.data.bcndata;
          this.tmsdashboardInfo = response.data.data.progressData;
          this.FinalTotalTrainee = response.data.data.FinalTotalTrainee;
          this.FinalTotalEnrollment = response.data.data.FinalTotalEnrollment;
          this.FinalTotalfemaleEnrollment =
            response.data.data.FinalTotalfemaleEnrollment;

          this.FinalTotalCertification =
            response.data.data.FinalTotalCertification;

          this.FinalTotalJobPlacement =
            response.data.data.FinalTotalJobPlacement;

          this.FinalTotalPercentageOfJobPlacement =
            response.data.data.FinalTotalPercentageOfJobPlacement;
          this.showTrainningStatistics = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
